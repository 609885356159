import useSWR from 'swr';

const X_API_Key = process.env.NEXT_PUBLIC_X_API_KEY;

const fetcher = async (url: string) => {
    // Ensure the API key is not undefined
    if (!X_API_Key) {
        throw new Error('API key is missing');
    }

    
    const response = await fetch(url, {
        headers: {
            'x-api-key': X_API_Key,  
        },
    });

    
    if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    
    return response.json();
};

export function useTokenPrices() {
    const { data, error } = useSWR('https://api.jiffyscan.xyz/v0/getPrices', fetcher, {
        refreshInterval: 300000, // 5 mins
        onSuccess: (data) => {
            // Cache the data in localStorage
            localStorage.setItem('tokenPrices', JSON.stringify(data));
        },
    });

    return {
        tokenPrices: data,
        isLoading: !error && !data,
        isError: error,
    };
}
